export function setupBookmarkReminder() {
  const bookmarkButton = document.getElementById("bookmarkReminder");
  if (bookmarkButton) {
    bookmarkButton.addEventListener("click", () => {
      if (window.sidebar && window.sidebar.addPanel) {
        // Firefox < 23
        window.sidebar.addPanel(document.title, window.location.href, "");
      } else if (window.external && "AddFavorite" in window.external) {
        // IE8
        window.external.AddFavorite(window.location.href, document.title);
      } else {
        alert(
          "Press " +
            (navigator.userAgent.toLowerCase().indexOf("mac") != -1
              ? "Command/Cmd"
              : "CTRL") +
            " + D to bookmark this page."
        );
      }
    });
  }
}
